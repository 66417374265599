/* You can add global styles to this file, and also import other style files */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

* {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important;
}

@font-face {
  font-family: 'Inter';
  src: url('~assets/Inter/Inter-VariableFont_slnt,wght.ttf');
}

.spinner-wrapper {
  width: 25px;
  height: 25px;
}

.mat-spinner {
  width: 23px !important;
  height: 23px !important;

  svg {
    width: 23px !important;
    height: 23px !important;
  }
}

.no-item:not(:first-child) {
  display: none;
}

.no-display {
  display: none;
}

@media (min-width: 680px) {
  ion-modal-view.modal {
    position: absolute;
    top: 50%;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    min-height: 240px !important;
    width: 100% !important;
  }
}

// pour la carte
.custom-icon {
  display: flex !important;
  background-color: var(--ion-color-primary);
  color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.5);
}

.custom-icon div {
  text-align: center;
  font-weight: bold;
  font-size: 12pt;
}

//COLOR
.ion-color-black {
  --ion-color-base: var(--ion-color-black);
}

.ion-color-grey900 {
  --ion-color-base: var(--ion-color-grey900);
}

.ion-color-grey600 {
  --ion-color-base: var(--ion-color-grey600);
}

.ion-color-grey400 {
  --ion-color-base: var(--ion-color-grey400);
}

.ion-color-grey200 {
  --ion-color-base: var(--ion-color-grey200);
}

.ion-color-grey100 {
  --ion-color-base: var(--ion-color-grey100);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
}

.ion-color-wait {
  --ion-color-base: var(--ion-color-wait);
  --ion-color-base-rgb: var(--ion-color-wait-rgb);
  --ion-color-contrast: var(--ion-color-wait-contrast);
  --ion-color-contrast-rgb: var(--ion-color-wait-contrast-rgb);
  --ion-color-shade: var(--ion-color-wait-shade);
  --ion-color-tint: var(--ion-color-wait-tint);
}

.ion-color-confirm {
  --ion-color-base: var(--ion-color-confirm);
}

.ion-color-orange500 {
  --ion-color-base: var(--ion-color-orange500);
}

.ion-color-green100 {
  --ion-color-base: var(--ion-color-green100);
}

.ion-color-confirm-text {
  --ion-color-base: var(--ion-color-confirm-text);
}

.ion-color-todo {
  --ion-color-base: var(--ion-color-todo);
}

.ion-color-todo-text {
  --ion-color-base: var(--ion-color-todo-text);
}

.ion-color-active {
  --ion-color-base: var(--ion-color-active);
}

.ion-color-done {
  --ion-color-base: var(--ion-color-done);
}

.ion-color-cancel {
  --ion-color-base: var(--ion-color-cancel);
}

.ion-color-yellow500 {
  --ion-color-base: var(--ion-color-yellow500);
}

.ion-color-yellow200 {
  --ion-color-base: var(--ion-color-yellow200);
}

.ion-color-yellow100 {
  --ion-color-base: var(--ion-color-yellow100);
}

.ion-color-blue900 {
  --ion-color-base: var(--ion-color-blue900);
}

.ion-color-blue600 {
  --ion-color-base: var(--ion-color-blue600);
}

.ion-color-blue700 {
  --ion-color-base: var(--ion-color-blue700);
}

.ion-color-blue500 {
  --ion-color-base: var(--ion-color-blue500);
}

.ion-color-blue400 {
  --ion-color-base: var(--ion-color-blue400);
}

.ion-color-blue200 {
  --ion-color-base: var(--ion-color-blue200);
}

.ion-color-blue100 {
  --ion-color-base: var(--ion-color-blue100);
}

.ion-color-suppr {
  --ion-color-base: var(--ion-color-suppr);
}

.ion-color-no-drop {
  --ion-color-base: var(--ion-color-no-drop);
}

.ion-color-vacation {
  --ion-color-base: var(--ion-color-vacation);
}

.ion-color-stockblue {
  --ion-color-base: var(--ion-color-stockblue);
  --ion-color-base-rgb: var(--ion-color-stockblue-rgb);
  --ion-color-contrast: var(--ion-color-stockblue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-stockblue-contrast-rgb);
  --ion-color-shade: var(--ion-color-stockblue-shade);
  --ion-color-tint: var(--ion-color-stockblue-tint);
}

.ion-color-stockblue100 {
  --ion-color-base: var(--ion-color-stockblue100);
}

.ion-color-stockblue300 {
  --ion-color-base: var(--ion-color-stockblue300);
}

.ion-color-stockblue700 {
  --ion-color-base: var(--ion-color-stockblue700);
}

.ion-color-stockblue900 {
  --ion-color-base: var(--ion-color-stockblue900);
}

.ion-color-cmd-create {
  --ion-color-base: var(--ion-color-cmd-create);
  --ion-color-base-rgb: var(--ion-color-cmd-create-rgb);
  --ion-color-contrast: var(--ion-color-cmd-create-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cmd-create-contrast-rgb);
  --ion-color-shade: var(--ion-color-cmd-create-shade);
  --ion-color-tint: var(--ion-color-cmd-create-tint);
}

.ion-color-cmd-validation {
  --ion-color-base: var(--ion-color-cmd-validation);
  --ion-color-base-rgb: var(--ion-color-cmd-validation-rgb);
  --ion-color-contrast: var(--ion-color-cmd-validation-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cmd-validation-contrast-rgb);
  --ion-color-shade: var(--ion-color-cmd-validation-shade);
  --ion-color-tint: var(--ion-color-cmd-validation-tint);
}

.ion-color-cmd-cancel {
  --ion-color-base: var(--ion-color-cmd-cancel);
  --ion-color-base-rgb: var(--ion-color-cmd-cancel-rgb);
  --ion-color-contrast: var(--ion-color-cmd-cancel-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cmd-cancel-contrast-rgb);
  --ion-color-shade: var(--ion-color-cmd-cancel-shade);
  --ion-color-tint: var(--ion-color-cmd-cancel-tint);
}

.ion-color-cmd-refused {
  --ion-color-base: var(--ion-color-cmd-refused);
  --ion-color-base-rgb: var(--ion-color-cmd-refused-rgb);
  --ion-color-contrast: var(--ion-color-cmd-refused-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cmd-refused-contrast-rgb);
  --ion-color-shade: var(--ion-color-cmd-refused-shade);
  --ion-color-tint: var(--ion-color-cmd-refused-tint);
}

.ion-color-cmd-partial {
  --ion-color-base: var(--ion-color-cmd-partial);
  --ion-color-base-rgb: var(--ion-color-cmd-partial-rgb);
  --ion-color-contrast: var(--ion-color-cmd-partial-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cmd-partial-contrast-rgb);
  --ion-color-shade: var(--ion-color-cmd-partial-shade);
  --ion-color-tint: var(--ion-color-cmd-partial-tint);
}

.ion-color-cmd-total {
  --ion-color-base: var(--ion-color-cmd-total);
  --ion-color-base-rgb: var(--ion-color-cmd-total-rgb);
  --ion-color-contrast: var(--ion-color-cmd-total-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cmd-total-contrast-rgb);
  --ion-color-shade: var(--ion-color-cmd-total-shade);
  --ion-color-tint: var(--ion-color-cmd-total-tint);
}

.ion-color-cmd-travel {
  --ion-color-base: var(--ion-color-cmd-travel);
  --ion-color-base-rgb: var(--ion-color-cmd-travel-rgb);
  --ion-color-contrast: var(--ion-color-cmd-travel-contrast);
  --ion-color-contrast-rgb: var(--ion-color-cmd-travel-contrast-rgb);
  --ion-color-shade: var(--ion-color-cmd-travel-shade);
  --ion-color-tint: var(--ion-color-cmd-travel-tint);
}

* {
  font-family: 'Inter', sans-serif;
}

ion-button {
  text-transform: none;
}

ion-accordion-group {
  ion-accordion {
    .ion-accordion-toggle-icon {
      color: var(--ion-color-blue600);
    }
  }
}

ion-input,
ion-select,
ion-label,
ion-item {
  --min-height: 0px;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}

.consentement-modal {
  --height: 52rem;
}

.rappel-modal {
  --width: 50rem;
}

.envoi-facture-modal,
.envoi-cr-modal,
.envoi-devis-modal,
.envoi-ordonnance-modal {
  --height: 16rem;
  --width: 30rem;
}

@media screen and (min-width: 973px) {
  .mobile {
    display: none;
  }
}

@media screen and (max-width: 972px) {
  .desktop {
    display: none;
  }
}

.detail-tarif-produit-modal {
  @media only screen and (min-width: 768px) {
    --height: 95%;
    --width: 50%;
  }
}

.detail-doublon-animal-modal {
  @media only screen and (min-width: 768px) {
    --height: 90%;
    --width: 40%;
  }
}

.detail-doublon-user-modal {
  @media only screen and (min-width: 768px) {
    --height: 86%;
    --width: 40%;
  }
}

.create-veto-modal {
  @media only screen and (min-width: 768px) {
    --height: 44rem;
    // --width: 40%;
  }
}

.add-commune-modal {
  @media only screen and (min-width: 768px) {
    --height: 55%;
    // --width: 30%;
  }
}

.create-asv-modal {
  @media only screen and (min-width: 768px) {
    --max-height: 325px;
    // --width: 30%;
  }
}

.create-structure-modal {
  @media only screen and (min-width: 768px) {
    --height: 90%;
    --width: 30%;
  }
}

.detail-tarif-acte-modal {
  @media only screen and (min-width: 768px) {
    --height: 75%;
    --width: 50%;
  }
}

.pdf-modal-size {
  @media only screen and (min-width: 768px) {
    --height: 95svh;
    --width: 80svw;
    // --width: 40%;
  }
}

.standalone-cr-modal-size {
  @media only screen and (min-width: 768px) {
    --height: 30rem;
    --width: 50rem;
  }
}

.export-dossier-medical-modal {
  @media only screen and (min-width: 768px) {
    --height: 30rem;
    --width: 45rem;
  }
}

.cr-text-modal {
  @media only screen and (min-width: 768px) {
    --height: 30rem;
    --width: 45rem;
  }
}

.add-local-structure-modal-size {
  @media only screen and (min-width: 768px) {
    --height: 48rem;
  }
}

.separation-modal-size {
  @media only screen and (min-width: 768px) {
    --height: 70svh;
    --width: 40svw;
    // --width: 40%;
  }
}

.deces-modal-size {
  @media only screen and (min-width: 768px) {
    --height: 12rem;
    --width: 20rem;
    // --width: 40%;
  }
}

.vaccin-modal-size {
  @media only screen and (min-width: 768px) {
    --height: 22rem;
    --width: 28rem;
    // --width: 40%;
  }
}

.preview-rdv-modal-size {
  @media only screen and (min-width: 768px) {
    --height: 95svh;
    --width: 70svw;
    // --width: 40%;
  }
}

.vacations-modal-size {
  @media only screen and (min-width: 768px) {
    --height: 76svh;
    --width: 60svw;
    // --width: 40%;
  }
}

.choix-animaux-devis-modal-size {
  @media only screen and (min-width: 768px) {
    --height: 36svh;
    --width: 30svw;
    // --width: 40%;
  }
}

.modal-size {
  @media only screen and (min-width: 1500px) {
    --height: 95svh;
    --width: 50svw;
    // --width: 40%;
  }
  @media only screen and (max-width: 1500px) {
    --height: 95svh;
    --width: 75svw;
    // --width: 40%;
  }
  @media only screen and (max-width: 750px) {
    --height: 100svh;
    --width: 100svw;
    // --width: 40%;
  }
}

.map-modal {
  @media only screen and (min-width: 768px) {
    --height: 85svh;
    --width: 70svw;
  }
}

.add-animal-rdv-modal {
  @media only screen and (min-width: 768px) {
    --height: 75svh;
    --width: 30svw;
  }
}

.edit-adresse-modal {
  @media only screen and (min-width: 768px) {
    --height: 17rem;
  }
}

fieldset {
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;

  legend {
    color: var(--ion-color-blue900);
    width: 100%;
    font-weight: 400;
    font-size: 0.8rem;
    margin-bottom: 4px;
  }
}

ion-action-sheet.rdv-sheet {
  --background: var(--ion-color-lightgray);
  --backdrop-opacity: 0.6;
  --button-background: var(--ion-color-lightgray);
  --button-background-selected: var(--ion-color-hiblue);
  --button-color-selected: var(--ion-color-white);
  --button-color: var(--ion-color-grey900);
  --color: var(--ion-color-grey900);

  .action-sheet-cancel.sc-ion-action-sheet-ios {
    color: white !important;
    font-weight: 600;
  }

  .action-sheet-selected.sc-ion-action-sheet-md {
    color: var(--ion-color-hiblue) !important;
  }
}

ion-popover {
  --width: auto;

  .item .sc-ion-label-ios-h {
    white-space: wrap;
  }
}

ion-modal.modal-default.show-modal:last-of-type {
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

.save-spinner {
  color: var(--ion-color-primary);
  margin-right: 0.5rem;
}
