/* Ionic Variables and Theming. */
/* This is just a placeholder file For more info, please see: */
/* https://ionicframework.com/docs/theming/basics */

/* To quickly generate your own theme, check out the color generator */
/* https://ionicframework.com/docs/theming/color-generator */

:root {
  font-size: calc(1rem + 0.125vw);
  --ion-font-family: 'Inter', sans-serif;
  --ion-color-primary: #5290b0;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #487f9b;
  --ion-color-primary-tint: #639bb8;

  --ion-color-secondary: #f5f9fe;
  --ion-color-secondary-contrast: var(--ion-color-primary);
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #4854e0;
  --ion-color-secondary-tint: #6370ff;

  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  --ion-color-success: #2dd36f;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #92949c;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-grey900: #31394d;
  --ion-color-grey600: #596175;
  --ion-color-grey400: #888fa0;
  --ion-color-grey200: #cbd0dc;
  --ion-color-grey100: #eeeef3;
  --ion-color-white: #ffffff;

  --ion-color-yellow500: #ecc26a;
  --ion-color-yellow200: #faf1e0;
  --ion-color-yellow100: #fdfaf5;

  --ion-color-blue900: #2c3d4d;
  --ion-color-blue700: #477496;
  --ion-color-blue600: #5290b0;
  --ion-color-blue500: #93bdd3;
  --ion-color-blue400: #b8d7e8;
  --ion-color-blue200: #dfeef5;
  --ion-color-blue150: #e5eff4;
  --ion-color-blue100: #f5f9fe;

  --ion-color-wait: #ff7777;
  --ion-color-wait-rgb: 255, 119, 119;
  --ion-color-wait-contrast: #000000;
  --ion-color-wait-contrast-rgb: 0, 0, 0;
  --ion-color-wait-shade: #e06969;
  --ion-color-wait-tint: #ff8585;

  --ion-color-suppr: #ffe7e7;
  // --ion-color-confirm: #f2a462;
  --ion-color-confirm: #ff8133;
  --ion-color-orange500: #ff884a;
  --ion-color-green100: #fff5e1;
  --ion-color-confirm-text: #440f06;
  --ion-color-todo: #fdee12;
  --ion-color-todo-text: #432905;
  // --ion-color-todo: #fffe9e;
  --ion-color-active: #01ff29;
  --ion-color-done: #76c676;
  --ion-color-cancel: #ff0202;
  --ion-color-black: #040506;

  --marker-color-0: #93bdd3;
  --marker-color-1: #5290b0;
  --marker-color-2: #ff7777;
  --marker-color-3: #7589ff;
  --marker-color-4: #ecc26a;
  --marker-color-5: #cb73c0;
  --marker-color-6: #76c676;
  --marker-color-7: #98a2af;
  --marker-color-8: #5d6bed;
  --marker-color-9: #c3b1e1;
  --marker-color-10: #ffb366;
  --marker-color-11: #66c2b6;
  --marker-color-12: #ffb3c6;
  --marker-color-13: #ffbf80;
  --marker-color-14: #b3d9ff;
  --marker-color-15: #b89aff;
  --marker-color-16: #d1f7a2;
  --marker-color-17: #ff8c6b;
  --marker-color-18: #f1a6a6;
  --marker-color-19: #ff9ed0;
  --marker-color-20: #ffb84d;
  --marker-color-21: #7fb3d5;
  --marker-color-22: #ffcc66;
  --marker-color-23: #e7a1e0;
  --marker-color-24: #a89f91;
  --marker-color-25: #66e1a1;
  --marker-color-26: #6fa0c3;
  --marker-color-27: #d1b99b;
  --marker-color-28: #c55a5a;
  --marker-color-29: #e2a3a0;

  --ion-color-no-drop: #f9a8af;
  --ion-color-vacation: #2dd36f40;
  --ion-color-rdv-interne: rgba(253, 2, 59, 0.78);

  --ion-color-stockblue: #2d82b7;
  --ion-color-stockblue100: #e5f0f9;
  --ion-color-stockblue300: #91c6e8;
  --ion-color-stockblue700: #1c5a88;
  --ion-color-stockblue900: #1c415e;

  // CMD/Stock COLOR
  --ion-color-stockblue: #2d82b7;
  --ion-color-stockblue-rgb: 45, 130, 183;
  --ion-color-stockblue-contrast: #000000;
  --ion-color-stockblue-contrast-rgb: 0, 0, 0;
  --ion-color-stockblue-shade: #1c415e;
  --ion-color-stockblue-tint: #428fbe;

  --ion-color-cmd-create: #e3b505;
  --ion-color-cmd-create-rgb: 227, 181, 5;
  --ion-color-cmd-create-contrast: #000000;
  --ion-color-cmd-create-contrast-rgb: 0, 0, 0;
  --ion-color-cmd-create-shade: #86510d;
  --ion-color-cmd-create-tint: #e6bc1e;

  --ion-color-cmd-validation: #2ba84a;
  --ion-color-cmd-validation-rgb: 43, 168, 74;
  --ion-color-cmd-validation-contrast: #000000;
  --ion-color-cmd-validation-contrast-rgb: 0, 0, 0;
  --ion-color-cmd-validation-shade: #1c5f2e;
  --ion-color-cmd-validation-tint: #40b15c;

  --ion-color-cmd-cancel: #cc2936;
  --ion-color-cmd-cancel-rgb: 204, 41, 54;
  --ion-color-cmd-cancel-contrast: #ffffff;
  --ion-color-cmd-cancel-contrast-rgb: 255, 255, 255;
  --ion-color-cmd-cancel-shade: #b42430;
  --ion-color-cmd-cancel-tint: #d13e4a;

  --ion-color-cmd-refused: #1e2019;
  --ion-color-cmd-refused-rgb: 30, 32, 25;
  --ion-color-cmd-refused-contrast: #ffffff;
  --ion-color-cmd-refused-contrast-rgb: 255, 255, 255;
  --ion-color-cmd-refused-shade: #1a1c16;
  --ion-color-cmd-refused-tint: #353630;

  --ion-color-cmd-partial: #cc4bc2;
  --ion-color-cmd-partial-rgb: 204, 75, 194;
  --ion-color-cmd-partial-contrast: #000000;
  --ion-color-cmd-partial-contrast-rgb: 0, 0, 0;
  --ion-color-cmd-partial-shade: #96358a;
  --ion-color-cmd-partial-tint: #d15dc8;

  --ion-color-cmd-total: #783f8e;
  --ion-color-cmd-total-rgb: 120, 63, 142;
  --ion-color-cmd-total-contrast: #ffffff;
  --ion-color-cmd-total-contrast-rgb: 255, 255, 255;
  --ion-color-cmd-total-shade: #6a377d;
  --ion-color-cmd-total-tint: #865299;

  --ion-color-cmd-travel: #36827f;
  --ion-color-cmd-travel-rgb: 54, 130, 127;
  --ion-color-cmd-travel-contrast: #000000;
  --ion-color-cmd-travel-contrast-rgb: 0, 0, 0;
  --ion-color-cmd-travel-shade: #244a4b;
  --ion-color-cmd-travel-tint: #4a8f8c;

  --ratio: 1.5rem;
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: 1rem;
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));
  --measure: 60ch;

  --font-size-base: 1rem;
  --font-size-biggish: 1.5rem;
  --font-size-big: 2rem;
}
